import Footer from "./footer";
import AppContext from './AppContext';
import { useContext, useState } from "react";

export default function NestedLayout({ children }) {
  const themeContext = useContext(AppContext);
  
  return (
    <>
                {children}
              
    </>
  );
}