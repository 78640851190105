import axiosInstance from "../lib/axiosInstance";

class authModel {

    __construct() {

    }
    adminlogin(data) {
        return axiosInstance.post("/auth/login",data);
    }
    
    guestlogin(data) {
        return axiosInstance.post("/auth/guest_user",data);
    }
    
    signUp(data) {
        return axiosInstance.post("/auth/signup",data);
    }
    async profile(data) {
        return (await axiosInstance.get("/auth/profile")).data;
    }
    changePassword(data) {
        return axiosInstance.post("/auth/change_password",data);
    }

    

    

}

export default new authModel();